.toasty-container {
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  padding: 1em;
  font-size: 13px;
  box-shadow: 0 5px 5px -5px #00000080;
}

.toasty-title {
  margin: 0;
  font-size: 1em;
}

.toasty-message {
  margin-top: .5em;
  margin-bottom: 0;
  font-size: .9em;
}

.toasty-success {
  background-color: #2ecc40;
}

.toasty-warning {
  background-color: #ff851b;
}

.toasty-error {
  background-color: #ff4136;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}
/*# sourceMappingURL=index.915487bc.css.map */
